<template>
    <div class="faq">
        <div
            v-for="(item, index) in faqs"
            :key="index"
            class="dropdown-faq">
            <h4 @click="toggleFaq(index)">{{ item.question }}</h4>
            <div
                v-if="activeIndex === index"
                class="content">
                <p>{{ item.answer }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        faqs: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            activeIndex: null
        }
    },
    methods: {
        toggleFaq(index) {
            if (this.activeIndex === index) {
                this.activeIndex = null
            } else {
                this.activeIndex = index
            }
        }
    }
}
</script>